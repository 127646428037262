<template>
  <v-container class="pa-10" fluid>
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="ml-10 mr-10 mt-10 mb-10">
              <v-data-table
                :headers="headers"
                :items="specifications"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar elevation="2" style="background: #f5f5f5">
                    <!-- <v-toolbar-title
                      style="margin-left: -14px"
                      class="mt-2 mb-2"
                      >Merchant Name : Seller
                    </v-toolbar-title> -->
                    <v-row>
                      <!-- <p style="margin-left: 224px" class="mt-5 mb-2">
                        Product Title : {{ product_title }}
                      </p> -->
                      <p class="mt-5 mb-2">
                        Product Title : {{ product_title }}
                      </p>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="primary"
                        dark
                        class="mb-2 mt-2 mr-2"
                        elevation="0"
                        @click="approveProduct"
                        v-if="totalRemaining == 0"
                      >
                        Approve Product
                      </v-btn>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn outlined small @click="assignViewedItem(item)"
                    >view</v-btn
                  >
                </template>

                <template v-slot:item.adquantity="{ item }">
                  <v-btn
                    text
                    @click="openAddQuantityModal(item)"
                    color="primary"
                  >
                    <v-icon small class="mr-2"> mdi-plus </v-icon> Quantity
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>

          <v-dialog v-model="dialog" width="700">
            <v-card>
              <v-card-title
                class="headline grey lighten-2 py-4 d-flex flex-row"
              >
                <p class="mb-0">Product Specification</p>
                <v-spacer></v-spacer>
                <v-btn elevation="0" color="red " @click="dialog = false">
                  close
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col>
                    <p>
                      Purchase Price :
                      <span>{{ specification.purchase_price }}</span>
                    </p>
                  </v-col>
                  <v-col>
                    <p>
                      Selling Price : <span>{{ specification.new_price }}</span>
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p>
                      Color : <span>{{ specification.color }}</span>
                    </p>
                  </v-col>
                  <v-col>
                    <p>
                      Size : <span>{{ specification.size }}</span>
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p>
                      Variant :
                      <span>{{ specification.weight_unit }}</span>
                    </p>
                  </v-col>
                  <v-col>
                    <p>
                      Product Unit : <span>{{ specification.unit }}</span>
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p>
                      Warranty unit :
                      <span>{{ specification.warranty_unit }}</span>
                    </p>
                  </v-col>
                  <v-col>
                    <p>
                      Warranty : <span>{{ specification.warranty }}</span>
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p>
                      Discount Type :
                      <span>{{ specification.discount.discount_type }}</span>
                    </p>
                  </v-col>
                  <v-col>
                    <p>
                      Discount Amount :
                      <span>{{ specification.discount.amount }}</span>
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p>
                      Discount Start Date :
                      <span>{{ specification.discount.start_date }}</span>
                    </p>
                  </v-col>
                  <v-col>
                    <p>
                      Discount End Date :
                      <span>{{ specification.discount.end_date }}</span>
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p>
                      Point Active Status:
                      <span>{{ specification.point.is_active }}</span>
                    </p>
                  </v-col>
                  <v-col>
                    <p>
                      Point Amount :
                      <span>{{ specification.point.point }}</span>
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p>
                      Point Start Date :
                      <span>{{ specification.point.start_date }}</span>
                    </p>
                  </v-col>
                  <v-col>
                    <p>
                      Point End Date :
                      <span>{{ specification.point.end_date }}</span>
                    </p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-card style="min-width: 100%; border: 1px solid black">
                    <v-card-title class="font-weight-medium pt-2"
                      >For Delivery</v-card-title
                    >
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <p>
                            Weight :
                            <span
                              >{{ specification.delivery_info.height
                              }}{{
                                specification.delivery_info.measument_unit
                              }}</span
                            >
                          </p>
                        </v-col>

                        <v-col>
                          <p>
                            Height :
                            <span
                              >{{ specification.delivery_info.height }}cm</span
                            >
                          </p>
                        </v-col>

                        <v-col>
                          <p>
                            Width :
                            <span
                              >{{ specification.delivery_info.width }}cm</span
                            >
                          </p>
                        </v-col>
                        <v-col>
                          <p>
                            Length :
                            <span
                              >{{ specification.delivery_info.length }}cm</span
                            >
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false">
                  I accept
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-dialog>

          <v-dialog v-model="addquantitydialog" width="750px" scrollable>
            <v-card>
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title
                  class="d-inline-block text-truncate ml-0"
                  style="max-width: 250px"
                  >{{ product_title }}</v-toolbar-title
                >

                <v-toolbar-title class="mx-3"
                  >Quantity:
                  {{ specification.seller_quantity }}</v-toolbar-title
                >

                <v-toolbar-title class="mx-3"
                  >Remain:
                  {{
                    specification.remaining -
                    (Number(totalSubtractCountOfWarhouse) +
                      Number(totalSubtractCountOfShop))
                  }}
                  <span
                    class="red--text font-weight-medium ml-4"
                    style="border 1px white"
                    v-if="
                      specification.remaining -
                        (Number(totalSubtractCountOfWarhouse) +
                          Number(totalSubtractCountOfShop)) <
                      0
                    "
                    >Limit crossed</span
                  ></v-toolbar-title
                >

                <v-spacer></v-spacer>

                <v-btn
                  v-if="specification.remaining > 0"
                  color="white"
                  elevation="0"
                  small
                  @click="confirmquantity"
                  :disabled="
                    specification.remaining !=
                    Number(totalSubtractCountOfWarhouse) +
                      Number(totalSubtractCountOfShop)
                  "
                >
                  <p class="green--text mb-0 font-weight-medium text-body-2">
                    Confirm
                  </p>
                </v-btn>
              </v-toolbar>

              <v-divider></v-divider>
              <v-card-title class="py-2 px-0 font-weight-bold"
                >Warehouse</v-card-title
              >
              <v-divider></v-divider>
              <v-simple-table fixed-header height="300px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-center">Quantity</th>
                      <th class="text-center">Subtract</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in warehouse" :key="index">
                      <td>{{ item.warehouse_name }}</td>
                      <td class="text-center">{{ item.previous_quantity }}</td>
                      <td class="d-flex justify-center">
                        <v-form lazy-validation>
                          <v-text-field
                            outlined
                            type="number"
                            style="max-width: 100px; height: 50px"
                            class="mt-1"
                            flat
                            dense
                            v-model="item.subtract"
                            @input="warhouseSubtract(item)"
                          ></v-text-field>
                        </v-form>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-divider></v-divider>
              <v-card-title class="py-2 px-0 font-weight-bold"
                >Shop</v-card-title
              >
              <v-divider></v-divider>

              <v-simple-table fixed-header height="300px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-center">Quantity</th>
                      <th class="text-center">Subtract</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in shop" :key="index">
                      <td>{{ item.shop_name }}</td>
                      <td class="text-center">{{ item.previous_quantity }}</td>
                      <td class="d-flex justify-center">
                        <v-text-field
                          outlined
                          type="number"
                          style="max-width: 100px; height: 50px"
                          class="mt-1"
                          flat
                          dense
                          v-model="item.subtract"
                          @input="shopSubtract(item)"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>



<script>
export default {
  data: () => ({
    product_title: "",
    product_id: "",
    dialog: false,
    addquantitydialog: false,
    shop: [],
    warehouse: [],
    subtractWarhouse: [],
    subtractShop: [],
    totalSubtractCountOfWarhouse: 0,
    totalSubtractCountOfShop: 0,
    quantity: 0,
    text: "",
    color: "",
    snackbar: false,

    headers: [
      { text: "Color", value: "color" },
      { text: "Size", value: "size" },
      { text: "Weight", value: "weight" },
      { text: "Variant", value: "weight_unit" },
      { text: "Quantity", value: "remaining" },
      { text: "Purchase Price", value: "purchase_price" },
      { text: "Selling Price", value: "new_price" },
      { text: "Actions", value: "actions", sortable: false },
      { text: "", value: "adquantity", sortable: false },
    ],

    specifications: [],
    contact: {},
    editedIndex: -1,

    valid_add_specification: false,

    validEditForm: false,
    quantityIndex: -1,
    specification: {
      discount: {},
      point: {},
      delivery_info: {},
      price: {},
    },
    totalRemaining: 1,
    show : false
  }),

  watch: {
    subtractWarhouse: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler: "totalProductQuantitiyCountOfWarhouse",
    },

    subtractShop: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler: "totalProductQuantitiyCountOfShop",
    },
  },

  methods: {
    initialize() {
      axios
        .get(`productdetails/showspec/${this.$route.query.id}/`)
        .then((response) => {
          if (Array.isArray(response.data.data)) {
            this.specifications = response.data.data;
            //console.log("this is for test", this.specifications)
            this.totalRemaining = this.specifications.reduce(
              (previous, current) => previous + current.remaining,
              0
            );
          }

          this.product_id = this.$route.query.id;
          this.unit = this.$route.unit;
          this.product_title = response.data.product_title;
        });
    },

    openAddQuantityModal(item) {
      //console.log(item);

      this.quantityIndex = this.specifications.findIndex(
        (spec) => spec.id == item.id
      );

      // Assign clicked specification
      Object.assign(this.specification, item);

      axios
        .get(`productdetails/quantity_info/${item.id}/`)
        .then((response) => {
          if (response.data.success) {
            this.shop = response.data.data.shop;
            this.warehouse = response.data.data.warehouse;
            this.addquantitydialog = true;
          } else {
            this.color = "red";
            //this.text = "Something went wrong !";
            this.text = response.data.message;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.color = "red";
          // this.text = "Something went wrong !";
          this.text = response.data.message;
          this.snackbar = true;
        });
    },

    // Total subtracted value count for each warhouse
    totalProductQuantitiyCountOfWarhouse() {
      this.totalSubtractCountOfWarhouse = 0;

      for (let n = 0; n < this.subtractWarhouse.length; n++) {
        // !isNaN(Number(this.subtractWarhouse[n].subtract))
        this.totalSubtractCountOfWarhouse =
          Number(this.totalSubtractCountOfWarhouse) +
          Number(this.subtractWarhouse[n].subtract);
      }
    },

    // Total subtracted value count for each shop
    totalProductQuantitiyCountOfShop() {
      this.totalSubtractCountOfShop = 0;

      for (let n = 0; n < this.subtractShop.length; n++) {
        this.totalSubtractCountOfShop =
          Number(this.totalSubtractCountOfShop) +
          Number(this.subtractShop[n].subtract);
      }
    },

    // Push new warhouse to array to track selected warhouse
    warhouseSubtract(item) {
      //check array length for new item or not
      if (this.subtractWarhouse.length > 0) {
        let index = this.subtractWarhouse.findIndex(
          (warhouse) => warhouse.warehouse_id == item.warehouse_id
        );

        if (index != -1) {
          this.subtractWarhouse[index].subtract = item.subtract;
        } else {
          this.subtractWarhouse.push(item);
        }
      } else {
        this.subtractWarhouse.push(item);
      }
    },

    // Push new shop to array to track selected shop
    shopSubtract(item) {
      if (this.subtractShop.length > 0) {
        let index = this.subtractShop.findIndex(
          (warhouse) => warhouse.shop_id == item.shop_id
        );
        if (index != -1) {
          this.subtractShop[index].subtract = item.subtract;
        } else {
          this.subtractShop.push(item);
        }
      } else {
        this.subtractShop.push(item);
      }
    },

    save() {
      axios
        .post(
          `productdetails/add_specification/${this.$route.query.id}/`,
          this.addItem
        )
        .then((response) => {
          if (response.data.success) {
            this.addDialog = false;
            // this.specifications.unshift(response.data.specification);
            // this.specifications[0].quantity = 0
            // this.addItem = this.defaultItem;

            // this.$refs.specificationForm.reset();

            // this.text = "Specification has been uploaded successfully";
            // this.color = "success";
            // this.snackbar = true;
            this.$router.go(this.$router.currentRoute);
          } else {
            // this.text = "Something went wrong !!";
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          //this.text = "Something went wrong !!";
          this.text = response.data.message;
          this.color = "error";
          this.snackbar = true;
        });
    },

    assignViewedItem(item) {
      // console.log("assign ", item);
      Object.assign(this.specification, item);
      this.dialog = true;
    },

    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios
          .post(`productdetails/delete_specification/${item.id}/`)
          .then((response) => {
            this.specifications = this.specifications.filter(
              (contact) => contact.id !== item.id
            );
          });
      }
    },

    confirmquantity() {
      let datas = {
        product_id: this.$route.query.id,
        specification_id: this.specification.id,
        warehouse: this.subtractWarhouse,
        shop: this.subtractShop,
      };

      // let total = 0;

      // datas.warehouse.forEach((element) => {
      //   total = Number(total) + Number(element.subtract);
      // });

      // datas.shop.forEach((element) => {
      //   total = Number(total) + Number(element.subtract);
      // });

      // this.addquantitydialog = false;

      // this.totalRemaining = this.totalRemaining - total;
      // this.specifications[this.quantityIndex].remaining = 0;

      // console.log(this.totalRemaining)

      // if (this.totalRemaining == 0) {
      //   this.text = "Product approved";
      //   this.color = "success";
      //   this.snackbar = true;
      // }

      // this.subtractWarhouse.length = 0;
      // this.subtractShop.length = 0;
      // this.totalSubtractCountOfWarhouse = 0;
      // this.totalSubtractCountOfShop = 0;

      axios
        .post(
          `/productdetails/subtract_spec_quantity/${this.specification.id}/`,
          datas
        )
        .then((response) => {
          // this.specifications[this.quantityIndex].quantity =
          //   this.specifications[this.quantityIndex].quantity + total;
          // this.addedwarehousequantity.length = 0;
          // this.addedshopquantity.length = 0;

          // console.log("Confirm ", response.data);

          if (response.data.success) {
            this.addquantitydialog = false;

            //this.text = "Quantity has been updated successfully";
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
            let total = 0;
            datas.warehouse.forEach((element) => {
              total = Number(total) + Number(element.subtract);
            });

            datas.shop.forEach((element) => {
              total = Number(total) + Number(element.subtract);
            });

            this.addquantitydialog = false;

            this.totalRemaining = this.totalRemaining - total;
            this.specifications[this.quantityIndex].remaining = 0;

            // let total = 0;

            // datas.warehouse.forEach((element) => {
            //   total = Number(total) + Number(element.subtract);
            // });

            // datas.shop.forEach((element) => {
            //   total = Number(total) + Number(element.subtract);
            // });

            // this.addquantitydialog = false;

            // this.totalRemaining = this.totalRemaining - total;
            // this.specifications[this.quantityIndex].remaining = 0;

            // // If Total remaining is 0 , product will be approved automatically

            // if (this.totalRemaining == 0) {
            //   axios
            //     .post(`product/merchent_approval/${this.$route.query.id}/`, {
            //       admin_status: "Confirmed",
            //     })
            //     .then((response) => {
            //       if (response.data.Success) {
            //         this.text = "Product has been approved";
            //         // this.text = response.data.message;
            //         this.color = "success";
            //         this.snackbar = true;
            //       }
            //     })
            //     .catch((err) => {
            //       this.text = "Something went wrong during product approval";
            //       this.color = "red";
            //       this.snackbar = true;
            //     });
            // } else {
            //   this.text = "Quantity has been updated successfully";
            //   // this.text = response.data.message;
            //   this.color = "success";
            //   this.snackbar = true;
            // }
          } else {
            this.text = "Quantity addition failed";
            this.color = "red";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.addquantitydialog = false;
          this.text = "Something went wrong ";
          // this.text = response.data.message;
          this.color = "red";
          this.snackbar = true;
        });

      this.subtractWarhouse.length = 0;
      this.subtractShop.length = 0;
      this.totalSubtractCountOfWarhouse = 0;
      this.totalSubtractCountOfShop = 0;
    },

    warehouseAdd(item) {
      //check array length for new item or not
      if (this.addedwarehousequantity.length > 0) {
        let index = this.addedwarehousequantity.findIndex(
          (warhouse) => warhouse.warehouse_id == item.warehouse_id
        );

        if (index != -1) {
          this.addedwarehousequantity[index].quantity = item.quantity;
        } else {
          this.addedwarehousequantity.push(item);
        }
      } else {
        this.addedwarehousequantity.push(item);
      }
    },

    shopAdd(item) {
      if (this.addedshopquantity.length > 0) {
        let index = this.addedshopquantity.findIndex(
          (shop) => shop.shop_id == item.shop_id
        );
        if (index != -1) {
          this.addedshopquantity[index].quantity = item.quantity;
        } else {
          this.addedshopquantity.push(item);
        }
      } else {
        this.addedshopquantity.push(item);
      }
    },

    approveProduct() {
      if (confirm("Do you want to Approve ?")) {
        // console.log("Approved");
        axios
          .post(`product/merchent_approval/${this.$route.query.id}/`, {
            admin_status: "Confirmed",
          })
          .then((response) => {
            if (response.data.Success) {
              this.text = "Product has been approved";
              this.color = "success";
              this.snackbar = true;
              this.totalRemaining = 1
              this.show = false
            }
          })
          .catch((err) => {
            this.text = "something went wrong";
            this.color = "red";
            this.snackbar = true;
          });
      }
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>
